// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Navbar
.nav-text {
    cursor: pointer;
}

.nav-text:hover {
    /* padding: 0 10px; */
    background-color: rgba(255, 255, 255, 0.3);
}

.menuselected{
    background-color: rgba(255, 255, 255, 0.3) !important;
}

.nav-text:hover a {
    color: #333333!important;
}

.nav-text a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    /* line-height: 14px; */
    position: relative;
    /* top: 5px; */
    font-family: 'Open Sans', sans-serif!important;
}

.active {
    background-color: white;
}

.active a {
    color: #333333!important;
}

.icon-button {
    position: relative;
    top: 3px;
}

.text-button {
    position: relative;
    top: -4px;
}

.fixed-table-loading {
    min-height: 400px!important;
    // background-color: #f8fafc!important;
}

@media (min-width: 768px) {
    .vl-left {
        border-left: 1px solid white;
        height: 100%;
        display: inline;
    }

    .vl-right {
        border-right: 1px solid white;
        height: 100%;
        display: inline;
    }
}

.page-list {
    display: none!important;
}

.navbar-dark .navbar-toggler {
    border-color: transparent!important;
}

.align-self-center {
    align-self: center;
}

.button-language {
    width: 30px !important;
    height: 30px !important;
    line-height: 14px !important;
    background-color: #999999 !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
}

.text-language {
    position: relative;
    top: 7px;
}

.example-spacer {
    flex: 1 1 auto;
}

// Footer
.footer-container {
    /* padding: 1.5em 1em; */
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    text-align: left;
    width: 100%;
}

.avis-legal-title {
    color: #666;
    font-size: 10px;
    font-weight: bold;
    font-family: "OpenSansSemibold", sans-serif;
}

.avis-legal-text {
    font-family: "OpenSansSemibold", sans-serif;
    color: #666;
    font-size: 10px;
    width: 100%;
    white-space: normal!important;
}

.border-footer {
    /* border-top: 1px solid rgba(61, 58, 47, 0.2); */
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    /* border-top: 2px solid rgba(51, 51, 51, 0.5); */
}

.footer {
    /* font-family: "Helvetica Neue", sans-serif!important; */
    font: 500 15px/15px 'Open Sans', sans-serif!important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.vl-right-footer {
    border-right: 1px solid rgba(51, 51, 51, 0.5);
    height: 100%;
    display: inline;
    margin-right: 0.5rem !important;
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: #efefef;
}

.example-button-container {
    display: flex;
    justify-content: center;
    width: 120px;
}

.button-language {
    background-color: #666666!important;
}

.button-language:hover {
    background-color: white!important;
    color: #666666!important;
}

.accessible-text {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif!important;
    color: #666;
    font-weight: bold;
    font-family: "OpenSansSemibold", sans-serif;
}
